
import { defineComponent } from 'vue';

export default defineComponent({
  emits: ['toggle'],
  setup(_props, { emit }) {
    function toggle(): void {
      emit('toggle');
    }

    return {
      toggle,
    };
  },
  data() {
    return {
      picture: require('./Swap.svg'),
      hoveredPicture: require('./HoveredSwap.svg'),
      hover: false,
    };
  },
  computed: {
    pictureHover() {
      if (this.hover == false) {
        return this.picture;
      } else {
        return this.hoveredPicture;
      }
    },
  },
});
