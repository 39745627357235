import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-df77dd86")
const _hoisted_1 = ["src"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "toggle-btn cursor-pointer",
    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)))
  }, [
    _createElementVNode("img", {
      src: _ctx.pictureHover,
      onMouseover: _cache[0] || (_cache[0] = ($event: any) => (_ctx.hover = true)),
      onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.hover = false)),
      width: "32",
      height: "32"
    }, null, 40, _hoisted_1)
  ]))
}