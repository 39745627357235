import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, withScopeId as _withScopeId } from "vue"

import { computed, onMounted, ref } from 'vue';
// Composables
import { useStore } from 'vuex';

// Components
import MyWallet from '@/components/cards/MyWallet/MyWallet.vue';
import PairPriceGraph from '@/components/cards/PairPriceGraph/PairPriceGraph.vue';
import TradeCard from '@/components/cards/TradeCard/TradeCard.vue';
import TrendingPairs from '@/components/cards/TrendingPairs/TrendingPairs.vue';
import Col3Layout from '@/components/layouts/Col3Layout.vue';
import usePoolFilters from '@/composables/pools/usePoolFilters';
import useBreakpoints from '@/composables/useBreakpoints';


export default _defineComponent({
  setup(__props) {

const { isMobile } = useBreakpoints();

/**
 * STATE
 */
const showPriceGraphModal = ref(false);

/**
 * COMPOSABLES
 */
const store = useStore();
const { setSelectedTokens } = usePoolFilters();
const { upToLargeBreakpoint } = useBreakpoints();

/**
 * COMPUTED
 */
const appLoading = computed(() => store.state.app.loading);

/**
 * METHODS
 */
function onPriceGraphModalClose() {
  showPriceGraphModal.value = false;
}

function togglePairPriceGraphModal() {
  showPriceGraphModal.value = !showPriceGraphModal.value;
}

/**
 * CALLBACKS
 */
onMounted(() => {
  // selectedPoolTokens are only persisted between the Home/Pool pages
  setSelectedTokens([]);
});

return (_ctx: any,_cache: any) => {
  const _component_BalLoadingBlock = _resolveComponent("BalLoadingBlock")!

  return (_openBlock(), _createBlock(Col3Layout, {
    offsetGutters: "",
    mobileHideGutters: "",
    class: "mt-10 lg:mt-40"
  }, {
    default: _withCtx(() => [
      (_unref(appLoading))
        ? (_openBlock(), _createBlock(_component_BalLoadingBlock, {
            key: 0,
            class: "h-96"
          }))
        : (_openBlock(), _createBlock(TradeCard, { key: 1 }))
    ]),
    _: 1
  }))
}
}

})