import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-534dea40")
const _hoisted_1 = {
  class: "px-[12px]",
  style: {"background-color":"#41365E"}
}
const _hoisted_2 = { class: "my-[6px] flex justify-center align-middle" }
const _hoisted_3 = { class: "toggle absolute" }
const _hoisted_4 = { class: "dark-back px-[12px]" }
_popScopeId()

import { computed, ref, watchEffect } from 'vue';

import TokenInput from '@/components/inputs/TokenInput/TokenInput.vue';
import { UseTrading } from '@/composables/trade/useTrading';
import useNumbers, { FNumFormats } from '@/composables/useNumbers';
import useTokens from '@/composables/useTokens';
import useVeBal from '@/composables/useVeBAL';
import { bnum } from '@/lib/utils';

import TradePairToggle from './TradePairToggle.vue';

import { sunriseDefinitions } from '@/pages/config';

type Props = {
  tokenInAmount: string;
  tokenInAddress: string;
  tokenOutAmount: string;
  tokenOutAddress: string;
  exactIn: boolean;
  priceImpact?: number;
  effectivePriceMessage?: UseTrading['effectivePriceMessage'];
  tradeLoading?: boolean;
};

/**
 * PROPS & EMITS
 */

export default _defineComponent({
  props: {
    tokenInAmount: { type: String, required: true },
    tokenInAddress: { type: String, required: true },
    tokenOutAmount: { type: String, required: true },
    tokenOutAddress: { type: String, required: true },
    exactIn: { type: Boolean, required: true },
    priceImpact: { type: Number, required: false },
    effectivePriceMessage: { type: null, required: false },
    tradeLoading: { type: Boolean, required: false }
  } as unknown as undefined,
  emits: ["update:tokenInAmount", "update:tokenInAddress", "update:tokenOutAmount", "update:tokenOutAddress", "update:exactIn", "amountChange"] as unknown as undefined,
  setup(__props: {
  tokenInAmount: string;
  tokenInAddress: string;
  tokenOutAmount: string;
  tokenOutAddress: string;
  exactIn: boolean;
  priceImpact?: number;
  effectivePriceMessage?: UseTrading['effectivePriceMessage'];
  tradeLoading?: boolean;
}, { emit }: { emit: ({
  (e: 'update:tokenInAmount', value: string): void;
  (e: 'update:tokenInAddress', value: string): void;
  (e: 'update:tokenOutAmount', value: string): void;
  (e: 'update:tokenOutAddress', value: string): void;
  (e: 'update:exactIn', value: boolean): void;
  (e: 'amountChange'): void;
}), expose: any, slots: any, attrs: any }) {

const props = __props
const sunrises = computed(() =>
  Object.values(sunriseDefinitions).map(a => a.bondTokenAddress)
);

/**
 * TYPES
 */




/**
 * COMPOSABLES
 */
const { fNum2 } = useNumbers();
const { getToken } = useTokens();
const { veBalTokenInfo } = useVeBal();

/**
 * STATE
 */
const _tokenInAmount = ref<string>('');
const _tokenInAddress = ref<string>('');
const _tokenOutAmount = ref<string>('');
const _tokenOutAddress = ref<string>('');

const isInRate = ref<boolean>(true);

/**
 * COMPUTED
 */
const missingToken = computed(
  () => !_tokenInAddress.value || !_tokenOutAddress.value
);

const missingAmount = computed(
  () => !_tokenInAmount.value || !_tokenOutAmount.value
);

const tokenIn = computed(() => getToken(_tokenInAddress.value));
const tokenOut = computed(() => getToken(_tokenOutAddress.value));

const rateLabel = computed(() => {
  if (missingToken.value || missingAmount.value) return '';

  if (props.effectivePriceMessage)
    return isInRate.value
      ? props.effectivePriceMessage.value.tokenIn
      : props.effectivePriceMessage.value.tokenOut;

  let rate, inSymbol, outSymbol;

  if (isInRate.value) {
    rate = bnum(_tokenOutAmount.value).div(_tokenInAmount.value).toString();
    inSymbol = tokenIn.value.symbol;
    outSymbol = tokenOut.value.symbol;
  } else {
    rate = bnum(_tokenInAmount.value).div(_tokenOutAmount.value).toString();
    inSymbol = tokenOut.value.symbol;
    outSymbol = tokenIn.value.symbol;
  }

  return `1 ${inSymbol} = ${fNum2(rate, FNumFormats.token)} ${outSymbol}`;
});

/**
 * METHODS
 */
function handleInAmountChange(value: string): void {
  emit('update:exactIn', true);
  emit('update:tokenInAmount', value);
  emit('amountChange');
}

function handleOutAmountChange(value: string): void {
  emit('update:exactIn', false);
  emit('update:tokenOutAmount', value);
  emit('amountChange');
}

function handleTokenSwitch(): void {
  emit('update:exactIn', !props.exactIn);
  emit('update:tokenInAmount', _tokenOutAmount.value);
  emit('update:tokenInAddress', _tokenOutAddress.value);
  emit('update:tokenOutAmount', _tokenInAmount.value);
  emit('update:tokenOutAddress', _tokenInAddress.value);
  emit('amountChange');
}

async function handleInputTokenChange(newTokenIn: string) {
  if (newTokenIn === _tokenOutAddress.value) {
    handleTokenSwitch();
    return;
  }
  emit('update:tokenInAddress', newTokenIn);
}

async function handleOutputTokenChange(newTokenOut: string) {
  if (newTokenOut === _tokenInAddress.value) {
    handleTokenSwitch();
    return;
  }
  emit('update:tokenOutAddress', newTokenOut);
}

/**
 * CALLBACKS
 */
watchEffect(() => {
  _tokenInAmount.value = props.tokenInAmount;
  _tokenInAddress.value = props.tokenInAddress;
  _tokenOutAmount.value = props.tokenOutAmount;
  _tokenOutAddress.value = props.tokenOutAddress;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(TokenInput, {
        amount: _tokenInAmount.value,
        address: _tokenInAddress.value,
        name: "tokenIn",
        excludedTokens: _unref(sunrises).concat([_unref(veBalTokenInfo)?.address]),
        ignoreWalletBalance: __props.tradeLoading,
        "onUpdate:amount": handleInAmountChange,
        "onUpdate:address": handleInputTokenChange
      }, null, 8, ["amount", "address", "excludedTokens", "ignoreWalletBalance"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(TradePairToggle, { onToggle: handleTokenSwitch })
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(TokenInput, {
        amount: _tokenOutAmount.value,
        address: _tokenOutAddress.value,
        name: "tokenOut",
        priceImpact: __props.priceImpact,
        noRules: "",
        noMax: "",
        disableNativeAssetBuffer: "",
        excludedTokens: _unref(sunrises).concat([_unref(veBalTokenInfo)?.address]),
        "onUpdate:amount": handleOutAmountChange,
        "onUpdate:address": handleOutputTokenChange
      }, null, 8, ["amount", "address", "priceImpact", "excludedTokens"])
    ])
  ]))
}
}

})